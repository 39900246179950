import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserLanguagesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UserLanguageFilter>;
}>;


export type UserLanguagesQuery = { __typename?: 'Query', userLanguages: { __typename?: 'UserLanguagesConnection', nodes: Array<{ __typename?: 'UserLanguage', displayName: string | null, name: string | null, languageCode: string, isoLangCode: string | null }> } | null };


export const UserLanguagesDocument = gql`
    query UserLanguages($filter: UserLanguageFilter) {
  userLanguages(filter: $filter) {
    nodes {
      displayName
      name
      languageCode
      isoLangCode
    }
  }
}
    `;

/**
 * __useUserLanguagesQuery__
 *
 * To run a query within a React component, call `useUserLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLanguagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<UserLanguagesQuery, UserLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLanguagesQuery, UserLanguagesQueryVariables>(UserLanguagesDocument, options);
      }
export function useUserLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLanguagesQuery, UserLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLanguagesQuery, UserLanguagesQueryVariables>(UserLanguagesDocument, options);
        }
export type UserLanguagesQueryHookResult = ReturnType<typeof useUserLanguagesQuery>;
export type UserLanguagesLazyQueryHookResult = ReturnType<typeof useUserLanguagesLazyQuery>;
export type UserLanguagesQueryResult = Apollo.QueryResult<UserLanguagesQuery, UserLanguagesQueryVariables>;