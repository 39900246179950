import { Box, LinearProgress } from '@mui/material'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'

import useGTM, { currentLocation, GtmEvent } from 'hooks/useGTM'

export default function GtmProvider({ children }: { children: ReactNode }) {
  const router = useRouter()
  const { initialize, trackEvent } = useGTM()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    initialize({
      dataLayer: {
        original_location: currentLocation(),
      },
    })

    const onRouteChangeComplete = (url: string) => {
      setLoading(false)
      trackEvent({
        event: GtmEvent.PageView,
        page_title: document.title,
        page_pathname: url,
        page_location: currentLocation(),
      })
    }
    const onRouteChangeStart = () => setLoading(true)
    const onRouteChangeError = (err: Error & { code?: unknown; cancelled?: boolean }) => {
      setLoading(false)
      if (err?.cancelled) return
      trackEvent({ event: GtmEvent.RouteChangeError })
    }

    // Attach listeners
    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeError', onRouteChangeError)
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Detach listeners
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeError', onRouteChangeError)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [initialize, router.events, trackEvent])

  return (
    <>
      {loading && (
        <Box
          sx={{
            zIndex: (theme) => theme.zIndex.appBar + 10,
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
          }}>
          <LinearProgress color="secondary" sx={{ height: 3 }} />
        </Box>
      )}
      {children}
    </>
  )
}
