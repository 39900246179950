import * as Types from '../types';

import { gql } from '@apollo/client';
export type ShortUserFieldsFragment = { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string };

export const ShortUserFieldsFragmentDoc = gql`
    fragment ShortUserFields on User {
  id
  accountId
  role
  email
  avatarUrl
  fullname
}
    `;