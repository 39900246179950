import { ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { useSession } from 'next-auth/react'
import { NextRouter, useRouter } from 'next/router'
import { ReactNode, useMemo } from 'react'

import LoginPage from 'pages/login'
import { publicRoutes } from 'routes'

import AuthLayout from 'components/layouts/AuthLayout'
import Preloader from 'components/Preloader'
import useSsoCookie from 'hooks/useSsoCookie'
import { createApolloClient, createAuthApolloClient, restoreCache } from 'utils/apollo-client'

type Props = {
  initialApolloState?: NormalizedCacheObject
  children: ReactNode
}

export default function AuthApolloProvider({ initialApolloState, children }: Props) {
  const { data: session, status } = useSession()
  const { cookieToken, ssoCookiePresent } = useSsoCookie()
  const requireAuth = useRequireAuth()

  const createAuthClient = useMemo(() => {
    const client = cookieToken ? createAuthApolloClient(cookieToken) : createApolloClient(session)
    if (initialApolloState) {
      return restoreCache(client, initialApolloState)
    }
    return client
  }, [cookieToken, session, initialApolloState])

  if (requireAuth && status === 'loading') {
    return <Preloader fullscreen />
  }

  if (requireAuth && status === 'unauthenticated') {
    if (ssoCookiePresent) return <Preloader fullscreen />
    return (
      <AuthLayout>
        <LoginPage />
      </AuthLayout>
    )
  }

  return <ApolloProvider client={createAuthClient}>{children}</ApolloProvider>
}

const isPublicPath = (pathname: NextRouter['pathname']) => publicRoutes.includes(pathname)

function useRequireAuth(): boolean {
  const router = useRouter()
  if (isPublicPath(router.pathname)) return false
  if (router.pathname === '/_error') return false
  if (router.pathname.startsWith('/_next')) return false
  return true
}
