import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserUpdatesSubscriptionVariables = Types.Exact<{
  userId: Types.Scalars['UUID'];
}>;


export type UserUpdatesSubscription = { __typename?: 'Subscription', userUpdated: { __typename?: 'UserSubscriptionPayload', id: string | null, user: { __typename?: 'User', isArchived: boolean, isDeleted: boolean | null } | null } | null };


export const UserUpdatesDocument = gql`
    subscription UserUpdates($userId: UUID!) {
  userUpdated(userId: $userId) {
    id: event
    user {
      isArchived
      isDeleted
    }
  }
}
    `;

/**
 * __useUserUpdatesSubscription__
 *
 * To run a query within a React component, call `useUserUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUpdatesSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserUpdatesSubscription(baseOptions: Apollo.SubscriptionHookOptions<UserUpdatesSubscription, UserUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserUpdatesSubscription, UserUpdatesSubscriptionVariables>(UserUpdatesDocument, options);
      }
export type UserUpdatesSubscriptionHookResult = ReturnType<typeof useUserUpdatesSubscription>;
export type UserUpdatesSubscriptionResult = Apollo.SubscriptionResult<UserUpdatesSubscription>;