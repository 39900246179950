import { Container, ContainerProps } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
} & ContainerProps

export default function FullscreenContainer({ children, ...props }: Props) {
  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        bgcolor: 'secondary.light',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}>
      {children}
    </Container>
  )
}
