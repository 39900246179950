import { PropsWithChildren } from 'react'
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form'

type Props<T extends FieldValues> = PropsWithChildren<{
  onSubmit?: SubmitHandler<T>
}> &
  Omit<React.HTMLAttributes<HTMLFormElement>, 'onSubmit'>

export default function GenericForm<T extends FieldValues>({
  children,
  onSubmit,
  ...props
}: Props<T>) {
  const methods = useFormContext<T>()
  // No form provider or form handler
  if (!methods || !onSubmit) {
    return <>{children}</>
  }
  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} {...props}>
      {children}
    </form>
  )
}
