import { useAccountUpdatesSubscription } from 'graphql/AccountUpdates.gen'

export default function useAccountEvent(accountId?: string | null) {
  const { data } = useAccountUpdatesSubscription({
    variables: { accountId: accountId ?? '' },
    skip: !accountId,
  })

  return {
    accountUpdated: data?.accountUpdated,
  }
}
