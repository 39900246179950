import {
  ButtonProps,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Stack,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'
import { FieldValues, SubmitHandler } from 'react-hook-form'

import { CloseHandler } from 'hooks/useConfirm'

import CancelButton from './CancelButton'
import CloseModalButton from './CloseModalButton'
import GenericForm from './form/GenericForm'
import SubmitFormButton from './SubmitFormButton'

type Props<T extends FieldValues> = {
  open: boolean
  dialogProps?: Partial<DialogProps>
  title?: ReactNode
  subtitle?: ReactNode
  titleProps?: Partial<DialogTitleProps>
  children?: ReactNode
  contentProps?: Partial<DialogContentProps>
  actionsProps?: Partial<DialogActionsProps>
  loading?: boolean
  primaryLabel?: ReactNode
  cancelLabel?: string
  /**
   * When `secondaryAction` is provided it is shown in the bottom left the dialog.
   */
  secondaryAction?: ReactNode
  /**
   * When `primaryHandler` is provided the handler is attached
   * to the primary button for the dialog.
   */
  primaryHandler?: () => void
  primaryHandlerProps?: ButtonProps
  /**
   * When `submitHandler` is provided dialog acts as a form with submit button.
   * Should be wrapped with <FormProvider /> context.
   */
  submitHandler?: SubmitHandler<T>
  cancelHandler?: CloseHandler
  closeHandler?: CloseHandler
  hideCancel?: boolean
  disableSubmit?: boolean
  disableClose?: boolean
}
export default function GenericDialog<T extends FieldValues>({
  open,
  dialogProps,
  title,
  subtitle,
  titleProps,
  contentProps,
  actionsProps,
  loading,
  submitHandler,
  cancelHandler,
  closeHandler,
  primaryLabel = 'Submit',
  primaryHandlerProps,
  primaryHandler,
  cancelLabel = 'Cancel',
  secondaryAction,
  hideCancel,
  disableSubmit,
  disableClose,
  children,
}: Props<T>) {
  return (
    <Dialog fullWidth open={open} onClose={closeHandler} {...dialogProps}>
      <GenericForm onSubmit={submitHandler}>
        <DialogTitle component="div" {...titleProps} sx={{ px: 4, pb: 3 }}>
          <Typography variant="h3">{title}</Typography>
          {subtitle && (
            <Typography variant="subtitle1" color="text.disabled" mt={1}>
              {subtitle}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent {...contentProps} sx={{ px: 4 }}>
          {children}
        </DialogContent>
        <DialogActions {...actionsProps} sx={{ px: 4, pb: 4 }}>
          {closeHandler && !disableClose && <CloseModalButton onClose={closeHandler} />}
          <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
            <>
              {secondaryAction ? secondaryAction : <div />}
              <Stack direction="row" gap={3}>
                {!hideCancel && (cancelHandler || closeHandler) && (
                  <CancelButton
                    onClick={cancelHandler || closeHandler}
                    disabled={loading}
                    label={cancelLabel}
                  />
                )}
                {!disableSubmit && (
                  <SubmitFormButton
                    size="medium"
                    color="secondary"
                    variant="contained"
                    disableFocusRipple
                    disabled={loading}
                    label={primaryLabel}
                    onClick={primaryHandler}
                    data-test-id="dialog-submit-button"
                    {...primaryHandlerProps}
                  />
                )}
              </Stack>
            </>
          </Stack>
        </DialogActions>
      </GenericForm>
    </Dialog>
  )
}
