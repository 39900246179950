import { Paper, PaperProps } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
} & PaperProps

export default function PageContainer({ children, ...props }: Props) {
  return (
    <Paper
      square
      elevation={0}
      {...props}
      sx={{
        py: 4,
        px: { xs: 2, sm: 5 },
        ...props.sx,
      }}>
      {children}
    </Paper>
  )
}
