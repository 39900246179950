import getConfig from 'next/config'
import { useCallback } from 'react'
import TagManager, { TagManagerArgs } from 'react-gtm-module'

const {
  publicRuntimeConfig: { gtmId },
} = getConfig()

const appVersion = 'v2'

export enum GtmEvent {
  PageView = 'page_view',
  RouteChange = 'RouteChange',
  RouteChangeError = 'RouteChangeError',
  // Auth
  Login = 'Login',
  LoginError = 'LoginError',
  LoginSSO = 'LoginSSO',
  LoginSSOError = 'LoginSSOError',
  LoginWithSSO = 'LoginWithSSO',
  LoginWithMagicLink = 'LoginWithMagicLink',
  LoginWithMagicLinkError = 'LoginWithMagicLinkError',
  Register = 'Register',
  RegisterError = 'RegisterError',
  UserUpdatedProfile = 'UserUpdatedProfile',
  UserUpdatedProfileError = 'UserUpdatedProfileError',
  UserResetPassword = 'UserResetPassword',
  UserResetPasswordError = 'UserResetPasswordError',
  UserChangePassword = 'UserChangePassword',
  UserUploadedAvatar = 'UserUploadedAvatar',
  ResetPassword = 'ResetPassword',
  ResetPasswordError = 'ResetPasswordError',
  ForgotPassword = 'ForgotPassword',
  ForgotPasswordError = 'ForgotPasswordError',
  // UI
  ShowCollboratorCursors = 'ShowCollboratorCursors',
  HideCollboratorCursors = 'HideCollboratorCursors',
  // Folders
  OpenFolder = 'OpenFolder',
  OpenArchiveFolder = 'OpenArchiveFolder',
  MoveFolder = 'MoveFolder',
  RenameFolder = 'RenameFolder',
  ArchiveFolder = 'ArchiveFolder',
  DeleteFolder = 'DeleteFolder',
  RestoreFolder = 'RestoreFolder',
  CreateNewFolder = 'CreateNewFolder',
  // Projects
  OpenProject = 'OpenProject',
  OpenArchiveProject = 'OpenArchiveProject',
  MoveProject = 'MoveProject',
  RenameProject = 'RenameProject',
  ArchiveProject = 'ArchiveProject',
  DeleteProject = 'DeleteProject',
  RestoreProject = 'RestoreProject',
  CreateNewProject = 'CreateNewProject',
  DuplicateProject = 'DuplicateProject',
  DragDropMoveProject = 'DragDropMoveProject',
  // Project Permissions
  ShareProject = 'ShareProject',
  UpdateProjectPermissions = 'UpdateProjectPermissions',
  RevokeProjectPermissions = 'RevokeProjectPermissions',
  // Variables
  CreateVariable = 'CreateVariable',
  DeleteVariable = 'DeleteVariable',
  UpdateVariable = 'UpdateVariable',
  UpdateVariableRanks = 'UpdateVariableRanks',
  // Variable Class
  CreateVariableClass = 'CreateVariableClass',
  ApproveVariableClass = 'ApproveVariableClass',
  ArchiveVariableClass = 'ArchiveVariableClass',
  // Connections
  CreateExternalConnection = 'CreateExternalConnection',
  UpdateExternalConnection = 'UpdateExternalConnection',
  DeleteInternalConnection = 'DeleteInternalConnection',
  CreateInternalConnection = 'CreateInternalConnection',
  UpdateInternalConnection = 'UpdateInternalConnection',
  DeleteExternalConnection = 'DeleteExternalConnection',
  CreateConnectionLink = 'CreateConnectionLink',
  UpdateConnectionLink = 'UpdateConnectionLink',
  // Messages
  CreateKeyMessage = 'CreateKeyMessage',
  UpdateKeyMessage = 'UpdateKeyMessage',
  DeleteKeyMessage = 'DeleteKeyMessage',
  CreateInternalMessage = 'CreateInternalMessage',
  UpdateInternalMessage = 'UpdateInternalMessage',
  DeleteInternalMessage = 'DeleteInternalMessage',
  CreateExternalMessage = 'CreateExternalMessage',
  UpdateExternalMessage = 'UpdateExternalMessage',
  DeleteExternalMessage = 'DeleteExternalMessage',
  // Time
  CreateTimeEvent = 'CreateTimeEvent',
  DeleteTimeEvent = 'DeleteTimeEvent',
  // Risk
  CreateRisk = 'CreateRisk',
  UpdateRisk = 'UpdateRisk',
  DeleteRisk = 'DeleteRisk',
  // Scenarios
  CreateScenario = 'CreateScenario',
  CreateScenarioVariable = 'CreateScenarioVariable',
  UpdateScenarioVariable = 'UpdateScenarioVariable',
  UpdateScenarioVariableAmount = 'UpdateScenarioVariableAmount',
  // Offers
  CreateOffer = 'CreateOffer',
  DeleteOffer = 'DeleteOffer',
  CreateOfferVariable = 'CreateOfferVariable',
  UpdateOfferVariable = 'UpdateOfferVariable',
  AlignOfferVariable = 'AlignOfferVariable',
  DealignOfferVariable = 'DealignOfferVariable',
  UpdateOfferVariableAmount = 'UpdateOfferVariableAmount',
  // Compliance
  CreateCompliance = 'CreateCompliance',
  UpdateCompliance = 'UpdateCompliance',
  DeleteCompliance = 'DeleteCompliance',
  // Align
  StartAlignmentQuiz = 'StartAlignmentQuiz',
  SkipAlignmentQuiz = 'SkipAlignmentQuiz',
  FinishAlignmentQuiz = 'FinishAlignmentQuiz',
  UpdateAlignmentResults = 'UpdateAlignmentResults',
  // Classes - Management
  CreateClass = 'CreateClass',
  RemoveClass = 'RemoveClass',
  CreateVideo = 'CreateVideo',
  UpdateVideo = 'UpdateVideo',
  RemoveVideo = 'RemoveVideo',
  RemoveDocument = 'RemoveDocument',
  UpdateDocument = 'UpdateDocument',
  // Classes - Viewing
  StartVideo = 'StartVideo',
  EndVideo = 'EndVideo',
  NextVideoAutoStart = 'NextVideoAutostart',
  NextVideoCancel = 'NextVideoCancel',
  NextVideoPlayNow = 'NextVideoPlayNow',
  // AI
  AIPrompt = 'AIPrompt',
  // Workshops
  SubmitLv2PreWorkshopSurvey = 'SubmitLv2PreWorkshopSurvey',
  SubmitLv3PreWorkshopSurvey = 'SubmitLv3PreWorkshopSurvey',
  SubmitPeerFeedbackSurvey = 'SubmitPeerFeedbackSurvey',
  SubmitWorkshopFeedback = 'SubmitWorkshopFeedback',
  SubmitLv3PostWorkshopSurvey = 'SubmitLv3PostWorkshopSurvey',
}

export type GtmRecord = Record<string, unknown>
export type DataEvent = { event: GtmEvent } & GtmRecord

export default function useGTM() {
  const initialize = useCallback(
    (params?: Partial<TagManagerArgs>) => TagManager.initialize({ gtmId, ...params }),
    []
  )
  const trackEvent = useCallback((dataLayer: DataEvent, dataLayerName?: string) => {
    TagManager.dataLayer({ dataLayer: { appVersion, ...dataLayer }, dataLayerName })
  }, [])

  return {
    gtmId,
    initialize,
    trackEvent,
  }
}

export const currentLocation = (): string => {
  if (typeof document === 'undefined') return ''
  return (
    document.location.protocol +
    '//' +
    document.location.hostname +
    document.location.pathname +
    document.location.search
  )
}
