import * as Types from '../../types';

import { gql } from '@apollo/client';
import { CoursesFieldsFragmentDoc } from './CoursesFields.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserCoursesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.CourseFilter>;
}>;


export type UserCoursesQuery = { __typename?: 'Query', coursesList: Array<{ __typename?: 'Course', id: string, name: string | null, accountId: string, languageCode: string | null, isDeleted: boolean, isPublished: boolean | null, publishedAt: string | null, createdAt: string, percentCompleted: number | null, account: { __typename?: 'Account', id: string, name: string | null } | null, courseUsers: { __typename?: 'CourseUsersConnection', totalCount: number, nodes: Array<{ __typename?: 'CourseUser', id: string, lastViewedAt: string, percentCompleted: number | null, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string } | null }> }, CourseItemsCount: { __typename?: 'CourseItemsConnection', totalCount: number } }> | null };


export const UserCoursesDocument = gql`
    query UserCourses($filter: CourseFilter) {
  coursesList(filter: $filter, orderBy: PUBLISHED_AT_DESC) {
    ...CoursesFields
  }
}
    ${CoursesFieldsFragmentDoc}`;

/**
 * __useUserCoursesQuery__
 *
 * To run a query within a React component, call `useUserCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCoursesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserCoursesQuery(baseOptions?: Apollo.QueryHookOptions<UserCoursesQuery, UserCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserCoursesQuery, UserCoursesQueryVariables>(UserCoursesDocument, options);
      }
export function useUserCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserCoursesQuery, UserCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserCoursesQuery, UserCoursesQueryVariables>(UserCoursesDocument, options);
        }
export type UserCoursesQueryHookResult = ReturnType<typeof useUserCoursesQuery>;
export type UserCoursesLazyQueryHookResult = ReturnType<typeof useUserCoursesLazyQuery>;
export type UserCoursesQueryResult = Apollo.QueryResult<UserCoursesQuery, UserCoursesQueryVariables>;