import * as Types from '../../types';

import { gql } from '@apollo/client';
import { ShortUserFieldsFragmentDoc } from '../../user/ShortUserFields.gen';
export type CoursesFieldsFragment = { __typename?: 'Course', id: string, name: string | null, accountId: string, languageCode: string | null, isDeleted: boolean, isPublished: boolean | null, publishedAt: string | null, createdAt: string, percentCompleted: number | null, account: { __typename?: 'Account', id: string, name: string | null } | null, courseUsers: { __typename?: 'CourseUsersConnection', totalCount: number, nodes: Array<{ __typename?: 'CourseUser', id: string, lastViewedAt: string, percentCompleted: number | null, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string } | null }> }, CourseItemsCount: { __typename?: 'CourseItemsConnection', totalCount: number } };

export const CoursesFieldsFragmentDoc = gql`
    fragment CoursesFields on Course {
  id
  name
  accountId
  languageCode
  isDeleted
  isPublished
  publishedAt
  createdAt
  percentCompleted
  account {
    id
    name
  }
  courseUsers {
    totalCount
    nodes {
      id
      lastViewedAt
      percentCompleted
      user {
        ...ShortUserFields
      }
    }
  }
  CourseItemsCount: courseItems {
    totalCount
  }
}
    ${ShortUserFieldsFragmentDoc}`;