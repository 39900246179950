import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import * as Sentry from '@sentry/nextjs'
import { createClient } from 'graphql-ws'
import ws from 'isomorphic-ws'
import { Session } from 'next-auth'
import getConfig from 'next/config'

import { GenericDbError } from 'graphql/error-handlers'

import { cache } from './apollo-cache'

const {
  publicRuntimeConfig: { graphqlEndpoint },
} = getConfig()

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

const isServer = typeof window === 'undefined' // true if we are in SSR mode

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const isAuthError =
    (networkError && 'statusCode' in networkError && networkError.statusCode === 401) ||
    graphQLErrors?.some((error) => error.message === 'jwt expired')

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, originalError }) => {
      if (isAuthError) {
        if (!isServer) {
          window.location.reload()
        }
        // Early return. Don't handle NetworkError if any.
        return
      } else {
        const errorMessage = `[GraphQL error]: Message: ${message}, Path: ${path}, Location: ${JSON.stringify(
          locations
        )}`
        console.error(errorMessage)

        // do not send known DB errors to Sentry
        if (!Object.values(GenericDbError).includes(message as GenericDbError)) {
          const scope = new Sentry.Scope()
          scope.setTag('error-type', 'graphql-error')
          Sentry.captureException(
            new Error(errorMessage, { cause: originalError || undefined }),
            () => scope
          )
        }
      }
    })
  }
  if (networkError) {
    if (isAuthError) {
      if (!isServer) {
        window.location.reload()
      }
      return
    }
    console.error(`[Network error]: ${networkError}`)
  }
})

// const uri = graphqlEndpoint
const uri = isServer
  ? graphqlEndpoint
      ?.replace('localhost', 'mc-graphql')
      ?.replace('graphql.makerconnect.local', 'mc-graphql')
  : graphqlEndpoint
const httpLink = createHttpLink({ uri })

const wsLink = (accessToken?: string) => {
  const connectionParams = accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined
  return new GraphQLWsLink(
    createClient({
      url: uri?.replace('http', 'ws'), // https:// to wss://
      connectionParams,
      webSocketImpl: ws,
    })
  )
}

const splitLink = (websocketLink: GraphQLWsLink) => {
  return split(
    ({ query }) => {
      if (isServer) {
        return false
      }
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    websocketLink,
    httpLink
  )
}

/**
 * Create authenticated apollo client with accessToken
 *
 * @param accessToken string | undefined
 * @param extraHeaders object | undefined
 * @returns ApolloClient
 */
export const createAuthApolloClient = (accessToken?: string, extraHeaders?: object) => {
  const authLink = setContext(async (_, { skipAuth, headers }) => {
    if (skipAuth) {
      return
    }
    const Authorization = accessToken ? `Bearer ${accessToken}` : undefined
    if (Authorization) {
      return { headers: { ...headers, Authorization, ...extraHeaders } }
    }
    return { headers: { ...headers, ...extraHeaders } }
  })

  const client = new ApolloClient({
    ssrMode: isServer,
    link: from([authLink, errorLink, splitLink(wsLink(accessToken))]),
    // no shared cache on the server
    cache: isServer ? new InMemoryCache() : cache,
    // typeDefs,
  })
  return client
}

/**
 * Create apollo client from session with accessToken
 *
 * @param session
 * @returns ApolloClient
 */
export const createApolloClient = (session?: Session | null) => {
  return createAuthApolloClient(session?.token)
}

/**
 * Restore the cache using the data passed from getStaticProps/getServerSideProps
 * combined with the existing cached data
 *
 * @param client ApolloClient
 * @param initialState NormalizedCacheObject
 * @returns ApolloClient
 */
export const restoreCache = (
  client: ApolloClient<NormalizedCacheObject>,
  initialState: NormalizedCacheObject
) => {
  const existingCache = client.extract()
  client.cache.restore({ ...existingCache, ...initialState })
  return client
}
