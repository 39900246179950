import MuiLink, { LinkProps } from '@mui/material/Link'
import React from 'react'

type ExternalLinkProps = React.PropsWithChildren<LinkProps>

const ExternalLink = React.forwardRef<HTMLAnchorElement, ExternalLinkProps>(function ExternalLink(
  { children, ...props },
  ref
) {
  return (
    <MuiLink
      target="_blank"
      rel="noreferrer"
      underline="none"
      fontWeight="400"
      ref={ref}
      {...props}>
      {children}
    </MuiLink>
  )
})

export default ExternalLink
