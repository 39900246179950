import Head from 'next/head'

import { createPageTitle } from './types'

type Props = {
  pageTitle?: string
}

export default function PageMeta({ pageTitle }: Props) {
  return (
    <Head>
      <title>{createPageTitle('Maker Connect', pageTitle)}</title>
    </Head>
  )
}
