import { useFlags } from 'launchdarkly-react-client-sdk'
import getConfig from 'next/config'

const {
  publicRuntimeConfig: { flags },
} = getConfig()

type Flags = 'registrationEnabled' | 'liveBlocksEnabled'

export type FeatureFlags = Record<Flags, boolean>

export function useLegacyFeatureFlags(): FeatureFlags {
  return flags
}

export function useFeatureFlag(flagName: string): boolean {
  const flags = useFlags()
  return flags[flagName]
}
