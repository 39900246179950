import Box, { BoxProps } from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { forwardRef } from 'react'

type PreloaderProps = {
  fullscreen?: boolean
  isInput?: boolean
  absolute?: boolean
  size?: number
} & BoxProps

const Preloader = forwardRef<HTMLDivElement, PreloaderProps>(function Preloader(props, ref) {
  const { fullscreen, isInput, absolute, size = isInput ? 21 : 40, ...rest } = props
  let styles: BoxProps = {}

  if (fullscreen) {
    styles = { height: '100vh', flex: '1', padding: 0 }
  } else if (absolute) {
    styles = {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: '2',
      sx: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
    }
  } else {
    styles = { flex: '1' }
  }

  const options = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 0',
    ...styles,
  }

  return (
    <Box {...options} ref={ref} {...rest} id="preloader">
      <CircularProgress color="secondary" size={size} />
    </Box>
  )
})

export default Preloader
