import { ReactNode } from 'react'

import FullscreenContainer from './FullscreenContainer'
import PageMeta from './PageMeta'
import { LayoutProps } from './types'

export default function AuthLayout({ pageTitle, children }: LayoutProps) {
  return (
    <>
      <PageMeta pageTitle={pageTitle} />
      <FullscreenContainer>{children}</FullscreenContainer>
    </>
  )
}

export const getLayout = (page: ReactNode) => <AuthLayout>{page}</AuthLayout>
