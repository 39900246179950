import { useUserUpdatesSubscription } from 'graphql/UserUpdates.gen'

export default function useUserActiveEvent(userId?: string | null) {
  const { data } = useUserUpdatesSubscription({
    variables: { userId: userId ?? '' },
    skip: !userId,
  })

  return { data }
}
