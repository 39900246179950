import { AlertColor, AlertProps, SnackbarProps, Stack } from '@mui/material'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import Preloader from 'components/Preloader'

import GenericToast from './GenericToast'

export type ToastEntry = {
  id?: ToastId
  open?: boolean
  message: string
  color: AlertColor
  duration?: SnackbarProps['autoHideDuration']
  action?: AlertProps['action']
  disableClickAway?: boolean
}
type ToastId = string
type ToastWithId = ToastEntry & { id: ToastId }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ToastModeContext = createContext<ReturnType<typeof useInit>>(null as any)

const uniqueId = () => new Date().getTime() + Math.random().toString(36).substring(2, 9)
const cleanUpTimeout = 5000

function createToast(toast: ToastEntry | string): ToastWithId {
  if (typeof toast === 'string') {
    return { id: uniqueId(), color: 'success', message: toast }
  }
  return { id: uniqueId(), ...toast }
}

const useInit = () => {
  const [toasts, setToasts] = useState<ToastWithId[]>([])

  useEffect(() => {
    // Check if we have any closed toasts
    if (toasts.some((t) => t.open === false)) {
      // Clean closed toasts after the clean up time has elapsed
      setTimeout(() => {
        setToasts((old) => old.filter((t) => t.open !== false))
      }, cleanUpTimeout)
    }
  }, [toasts])

  const addToast = useCallback((toastData: ToastEntry | string): ToastId => {
    const toast = createToast(toastData)
    setToasts((old) => {
      const index = old.findIndex((t) => t.id === toast.id)
      if (index < 0) {
        // Create new toast
        return [...old, createToast(toast)]
      }
      // Replace the toast content by id
      return [...old.slice(0, index), toast, ...old.slice(index + 1)]
    })
    return toast.id
  }, [])

  const removeToast = useCallback((id: string) => {
    return setToasts((old) => {
      const index = old.findIndex((t) => t.id === id)
      return [
        ...old.slice(0, index),
        // Hide the removed toast
        { ...old[index], open: false },
        ...old.slice(index + 1),
      ]
    })
  }, [])

  return {
    toasts,
    addToast,
    removeToast,
  }
}

export function ToastModeProvider({ children }: { children?: React.ReactNode }) {
  const context = useInit()
  const { toasts, removeToast } = context
  const remove = useCallback((id: string) => () => removeToast(id), [removeToast])

  // fixes react hydration error: https://nextjs.org/docs/messages/react-hydration-error
  const [isClient, setIsClient] = useState(false)
  const [hasChatBubble, setHasChatBubble] = useState(false)
  const [hasChatBubbleTitle, setHasChatBubbleTitle] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    if (!window.zE || hasChatBubble) return

    setHasChatBubble(true)
  }, [toasts, hasChatBubble, hasChatBubbleTitle, isClient])

  useEffect(() => {
    if (!isClient) return

    const interval = setInterval(() => {
      const hasTitle =
        document?.querySelectorAll('[data-product="web_widget"] + div > div > div')?.length > 0

      setHasChatBubbleTitle(hasTitle)
    }, 1000)

    return () => clearInterval(interval)
  }, [hasChatBubbleTitle, isClient])

  if (!isClient) return <Preloader fullscreen />

  let bottom = 0
  if (hasChatBubble) {
    bottom = 50
    if (hasChatBubbleTitle) {
      bottom = 100
    }
  }

  return (
    <ToastModeContext.Provider value={context}>
      {children}
      <Stack spacing={1} sx={{ position: 'fixed', bottom, right: 0, zIndex: 9999999 }}>
        {toasts.map((toast) => (
          <GenericToast open {...toast} key={toast.id} onClose={remove(toast.id)} />
        ))}
      </Stack>
    </ToastModeContext.Provider>
  )
}

export const useToast = () => {
  return useContext(ToastModeContext)
}
