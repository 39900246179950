export const colors = {
  greyLight: '#E7E6E5',
  greyDark: '#393D40',
  blue: '#85BBC9',
  blueLight: '#85BBC9',
  blueDark: '#2D728F',
  green: '#83CCB7',
  greenDark: '#00B887',
  greenLight: '#B2E5D6',
  yellow: '#FFD77D',
  yellowDark: '#F5C23B',
  yellowDarkHover: '#e9ab09',
  yellowLight: '#FDF1D1',
  red: '#D67690',
  redDark: '#DE3F64',
  brightRed: '#F0142F',
  redLight: '#E5B0BE',
  text: '#464646',
  textLight: '#7F7F7F',
  bgDarkText: '#C2BBB8',
  bgDarkTextLight: '#FAF9F8',
  borderDark: '#707070',
  backgroundDark: '#1F2428',
  background: '#BFBFBF',
  backgroundLight: '#F2F2F2',
  backgroundSecondary: '#FCF4E0',
  white: '#FFFFFF',
  black: '#000000',
} as const
