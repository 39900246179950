import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material'
import { ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  name: string
  label: ReactNode
  labelProps?: Omit<FormControlLabelProps, 'label' | 'control'>
} & CheckboxProps

export default function ControlCheckBox({ name, label, labelProps, ...props }: Props) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          componentsProps={{ typography: { color: 'text.secondary' } }}
          {...field}
          {...labelProps}
          control={<Checkbox color="secondary" size="small" checked={field.value} {...props} />}
        />
      )}
    />
  )
}
