import Button, { ButtonProps } from '@mui/material/Button'
import { ReactNode } from 'react'

type Props = {
  label?: ReactNode
} & ButtonProps
export default function SubmitFormButton({ label, ...rest }: Props) {
  return (
    <Button variant="contained-secondary" size="small" type="submit" {...rest}>
      {label || 'Submit'}
    </Button>
  )
}
