import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountUpdatesSubscriptionVariables = Types.Exact<{
  accountId: Types.Scalars['UUID'];
}>;


export type AccountUpdatesSubscription = { __typename?: 'Subscription', accountUpdated: { __typename?: 'AccountSubscriptionPayload', id: string | null, account: { __typename?: 'Account', isArchived: boolean } | null } | null };


export const AccountUpdatesDocument = gql`
    subscription AccountUpdates($accountId: UUID!) {
  accountUpdated(accountId: $accountId) {
    id: event
    account {
      isArchived
    }
  }
}
    `;

/**
 * __useAccountUpdatesSubscription__
 *
 * To run a query within a React component, call `useAccountUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountUpdatesSubscription({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountUpdatesSubscription(baseOptions: Apollo.SubscriptionHookOptions<AccountUpdatesSubscription, AccountUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AccountUpdatesSubscription, AccountUpdatesSubscriptionVariables>(AccountUpdatesDocument, options);
      }
export type AccountUpdatesSubscriptionHookResult = ReturnType<typeof useAccountUpdatesSubscription>;
export type AccountUpdatesSubscriptionResult = Apollo.SubscriptionResult<AccountUpdatesSubscription>;