import Button, { ButtonProps } from '@mui/material/Button'
import { forwardRef } from 'react'

type Props = { label?: string } & ButtonProps

const CancelButton = forwardRef<HTMLButtonElement, Props>(function CancelButton(props, ref) {
  return (
    <Button
      variant="text"
      color="secondary"
      size="small"
      data-test-id="cancel-button"
      {...props}
      ref={ref}>
      {props.label || 'Cancel'}
    </Button>
  )
})

export default CancelButton
