import Close from '@mui/icons-material/Close'
import { IconButton, IconButtonProps, SvgIconTypeMap } from '@mui/material'
import React from 'react'

export type CloseModalButtonProps = {
  href?: string
  onClose?: () => void
  edge?: 'start' | 'end'
  fontSize?: SvgIconTypeMap['props']['fontSize']
} & Omit<IconButtonProps, 'ref'>
export default function CloseModalButton({
  edge = 'end',
  fontSize = 'medium',
  onClose,
  ...buttonProps
}: CloseModalButtonProps) {
  return (
    <IconButton
      size="large"
      tabIndex={-1}
      onClick={onClose}
      data-test-id="close-modal-button"
      sx={(theme) => ({
        position: 'absolute',
        top: theme.spacing(2),
        left: edge === 'start' ? theme.spacing(2) : undefined,
        right: edge === 'end' ? theme.spacing(2) : undefined,
        zIndex: theme.zIndex.drawer,
      })}
      {...buttonProps}>
      <Close fontSize={fontSize} />
    </IconButton>
  )
}
