import getConfig from 'next/config'

const {
  publicRuntimeConfig: {
    v1WorkshopsDashboardEndpoint,
    v1LogoutEndpoint,
    v1AppUrlMagicLink,
    cookieDomain,
    SSOLoginUrl,
  },
} = getConfig()

export { cookieDomain }

export const externalRoutes = {
  v1: {
    logout: `${v1LogoutEndpoint}`,
    magicLink: `${v1AppUrlMagicLink}`,
    workshopsDashboard: `${v1WorkshopsDashboardEndpoint}`,
  },
  SSOLoginUrl,
} as const
